import React from 'react';
import {Link} from "react-router-dom";

const Button = ({ color, title, className, link }) => {

    return (
        <Link to={`/${link}`} className={`button elevation010 ${color} ${className}`}>
                {title}
        </Link>
    );
};

export default Button;
