import React from "react";
import "./styles/tokens.scss"

function LoadingSpinner() {
    return (
        <div className="loading-spinner">

            <svg
                className="spinner"
                width="50px"
                height="50px"
                viewBox="0 0 50 50"
            >
                <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                />
            </svg>

        </div> // Display loading indicator
    )
}

export default LoadingSpinner;