import logo from './logo.svg';
import './styles/Main.css';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import NavigationDesktop from "./navigation/NavigationDesktop";
import NavigationMobile from "./navigation/NavigationMobile";



class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false //This is where I am having problems
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 1450 });
    }

    render() {
        const isDesktop = this.state.isDesktop;




        return [
            <div key={1}>
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />

                { isDesktop ? (
                    <NavigationDesktop key={2}/>
                ) : (
                    <NavigationMobile key={3}/>
                )}
            </div>
        ];
    }
}

// function Header() {
//
//     return (
//         <header className="header">
//
//
//             <NavigationDesktop/>
//
//
//         </header>
//     );
// }

export default Header;
