// Move all imports to the top of the file
import logo from './logo.svg';
import './styles/Main.scss';
import './styles/shared.scss';
import Header from "./Header";
import Footer from "./navigation/Footer";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { RetrieveBrowserLanguage } from "./RetrieveBrowserLanguage";
import { LanguageProvider } from './LanguageContext';
import Icons from "./Icons";
import { Helmet } from "react-helmet";
import getString from './getStrings';
import LanguageSwitcher from "./LanguageSwitchButton";
import CustomIcon from "./Icons";
import FadeWrapper from "./FadeWrapper";
import LoadingSpinner from "./LoadingSpinner";

// Lazy load components
const Home = lazy(() => import('./Home'));
const Portfolio = lazy(() => import('./Portfolio'));
const Skills = lazy(() => import('./Skills'));
const Contact = lazy(() => import('./Contact'));
const About = lazy(() => import('./About'));
const NotFound = lazy(() => import('./NotFound'));
const language = RetrieveBrowserLanguage();

const AbsentReflectionLogo = lazy(() => import('./PortfolioItems/Portfolio-AbsentReflectionLogo'));
const AbsentReflectionWebsite = lazy(() => import('./PortfolioItems/Portfolio-AbsentReflectionWebsite'));
const MechanicalHeartLogo = lazy(() => import('./PortfolioItems/Portfolio-MechanicalHeartLogo'));
const PokeLibrary = lazy(() => import('./PortfolioItems/Portfolio-Pokelibrary'));
const Minute = lazy(() => import('./PortfolioItems/Portfolio-Minute'));



function App() {

    const [showSpinner, setShowSpinner] = useState(true); // State to manage spinner visibility
    const [fadeInInit, setFadeInInit] = useState(false); // State for controlling initial fade-in effect

    useEffect(() => {
        // Use the timer to hide the spinner and trigger fade-in effect
        const timer = setTimeout(() => {
            setShowSpinner(false);
            setFadeInInit(true); // Trigger fade-in after spinner disappears
        }, 3000); // Adjust the delay as needed

        return () => {
            clearTimeout(timer); // Clean up the timer on unmount
        };
    }, []);

    return (
        <LanguageProvider>

              <Router>
                  <ScrollToTop /> {/* This ensures that it scrolls to top on every route change */}
                  <div className="App">
                      <Header />
                      {/*<Helmet>*/}
                      {/*    <title>Home - My Website</title>*/}
                      {/*</Helmet>*/}
                          {/*<FadeWrapper> /!* Apply the fade effect for route changes *!/*/}
                                  <Suspense fallback={<LoadingSpinner />}>

                                      <div className={`content`}>
                                        <Routes>
                                            <Route exact path="/" element={<Home />} />
                                            <Route exact path="/about" element={<About />} />
                                            <Route exact path="/portfolio" element={<Portfolio />} />
                                            <Route exact path="/skills" element={<Skills />} />
                                            <Route exact path="/contact" element={<Contact />} />

                                            {/*PORTFOLIO ITEMS*/}
                                            <Route exact path="/AbsentReflection-Logo" element={<AbsentReflectionLogo />} />
                                            <Route exact path="/AbsentReflection-Website" element={<AbsentReflectionWebsite />} />
                                            <Route exact path="/MechanicalHeart-Logo" element={<MechanicalHeartLogo />} />
                                            <Route exact path="/PokeLibrary" element={<PokeLibrary />} />
                                            <Route exact path="/Minute" element={<Minute />} />

                                            {/*/!*downloads*!/*/}
                                            {/*<Route exact path="/MechanicalHeart-pdf" element={<MechanicalHeartPDF />} />*/}

                                            <Route path="*" element={<NotFound />} />
                                        </Routes>
                                      </div>

                                  </Suspense>
                          {/*</FadeWrapper>*/}
                      <Footer />

                  </div>




              </Router>
        </LanguageProvider>
  );
}

export default App;
