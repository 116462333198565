import { useState } from "react";
import {useNavigate} from "react-router-dom";
import getString from "./getStrings";
import { useLanguage } from './LanguageContext';
import Contact from "./Contact";

const ContactForm = () => {
    const { language } = useLanguage();
    const pageName = "contact";

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isPending, setIsPending] = useState(false);

    const navigate = useNavigate();
    const redirectSuccess = event => navigate('/', { replace: true });

    const handleSubmit = (e) => {
        e.preventDefault();
        const formSubmission = { firstName, lastName, email, phoneNumber, subject, message };

        setIsPending(true);


        // PHP Integration

        fetch('/data/cellularEmailHandler.php', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formSubmission)
        }).then((response) => response.json())
            .then((data) => {
                setIsPending(false);

                if (data.success) {
                    redirectSuccess();
                    console.log('Email sent successfully!');
                } else {
                    console.log('Failed to send email.');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                console.log('An error occurred while sending the email.');
            });


        console.log(formSubmission);
    }

    const Required = () => {
        return (
            <span className="required" aria-hidden="true" style={{ color: 'red' }}>
                *
            </span>
        )
    }

    {getString(`${pageName}.firstName.label`, language)}
    {getString(`${pageName}.lastName.placeholder`, language)}

    return (
        <div className="contactForm">
            <form action="" onSubmit={handleSubmit}>
                <div className="formGroup">
                    <div className="formField">
                        <label htmlFor="firstName">{getString(`${pageName}.firstName.label`, language)}<Required /></label>
                        <input
                            type="text"
                            required={true}
                            name={"firstName"}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder={getString(`${pageName}.firstName.placeholder`, language)}
                        />
                    </div>

                    <div className="formField">
                        <label htmlFor="">{getString(`${pageName}.lastName.label`, language)}<Required /></label>
                        <input
                            type="text"
                            required={true}
                            name={"lastName"}
                            placeholder={getString(`${pageName}.lastName.placeholder`, language)}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="formField">
                    <label htmlFor="">{getString(`${pageName}.email.label`, language)}<Required /></label>
                    <input
                        type="email"
                        required={true}
                        name={"emailAdress"}
                        placeholder={getString(`${pageName}.email.placeholder`, language)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="formField">
                    <label htmlFor="">{getString(`${pageName}.phoneNumber.label`, language)}<Required /></label>
                    <input
                        type="text"
                        required={true}
                        name={"phoneNumber"}
                        placeholder={getString(`${pageName}.phoneNumber.placeholder`, language)}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>

                <div className="formField">
                    <label htmlFor="">{getString(`${pageName}.subject.label`, language)}<Required /></label>
                    <input
                        type="text"
                        required={true}
                        name={"subject"}
                        placeholder={getString(`${pageName}.subject.placeholder`, language)}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>


                <div className="formField">
                    <label htmlFor="">{getString(`${pageName}.message.label`, language)}<Required /></label>
                    <textarea
                        required={true}
                        name={"message"}
                        placeholder={getString(`${pageName}.message.placeholder`, language)}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                <div className="formField">
                </div>

                // Robot check//

                <div className="formField">
                    { !isPending && <button className="button blue" type={"submit"}>{getString(`${pageName}.submit.label`, language)}</button> }
                    { isPending && <button className="button" disabled type={"submit"}>{getString(`${pageName}.submit.loading`, language)}</button> }
                </div>

            </form>
        </div>
    )
}

export default ContactForm;