import React from 'react';
import {Link} from "react-router-dom";
import ContentBlock from "./ContentBlock";
import skillImages from "../assets/images/skillsImages.svg";
import AngleAsset from "../assets/Angle";
import SkillItem from "../SkillItem";
import getString from "../getStrings";
import projectTileSmall from "./projectTileSmall";
import ContentImage from "./ContentImage";
import thomasDiploma from "../assets/images/thomas_diploma.jpeg";
import { useLanguage } from '../LanguageContext';
import uxd from "../assets/images/uxd.jpg";
import mechanicalHeartImage from "../assets/images/MechanicalHeartTest.svg";
import Button from "../Button";
import SkillProjectHighlightSection from "./SkillProjectHighlightSection"; // Ensure correct import

const GenericSkillSection = ({ skillArray, color, children, imageLocation }) => {
    const { language } = useLanguage();
    const pageName = 'skills'; // Declare the page name if used in getString


    // const skillArray = [
    //     {
    //         title: "uxd",
    //         link: "/",
    //         image: uxd,  // Replace with relevant image for this skill
    //         alt: "Image for Visual Design",
    //         links: [
    //             { title: "Page A", link: "/pageA", image: mechanicalHeartImage, alt: "Page A Image" },
    //             { title: "Page B", link: "/pageB", image: mechanicalHeartImage, alt: "Page B Image" },
    //             { title: "Page C", link: "/pageC", image: mechanicalHeartImage, alt: "Page C Image" }
    //         ]
    //     },
    //     {
    //         title: "uid",
    //         link: "/",
    //         image: uxd,  // Replace with relevant image for this skill
    //         alt: "Image for Visual Design",
    //         links: [
    //             { title: "Page A", link: "/pageA", image: mechanicalHeartImage, alt: "Page A Image" },
    //             { title: "Page B", link: "/pageB", image: mechanicalHeartImage, alt: "Page B Image" },
    //             { title: "Page C", link: "/pageC", image: mechanicalHeartImage, alt: "Page C Image" }
    //         ]
    //     }
    //     // Add more skills and their links
    // ];

    return (
        <>
            {skillArray.map((skillItem, skillIndex) => (
                <React.Fragment key={skillIndex}>
                    <div className={"innerWrapper " + `${color}`}>
                        <div className={"skills innerContent headerContent withImage " + `${imageLocation}`}>
                            <ContentBlock page={'skills'} section={skillItem.title} headerType={'h3'}>



                                {/*<h4>{getString(`generic.highlightText`, language)}</h4>*/}

                                {/*<div className="ProjectHighlightWrapper">*/}
                                {/*    /!* Pass skillItem and skillIndex as props to the SkillProjectHighlightSection *!/*/}
                                {/*    <SkillProjectHighlightSection skillItem={skillItem} skillIndex={skillIndex} />*/}
                                {/*</div>*/}

                            </ContentBlock>

                            <ContentImage image={skillItem.image} className={'unsureIfICanHelpImage elevation100 ' + skillItem.title} aspect={'square'} />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    );

};

export default GenericSkillSection;
