import {Helmet} from "react-helmet";
import React from "react";

const PageTitle = ({title}) => {
    return (
        <Helmet>
            <title>Cellular-Design - { title }</title>
        </Helmet>
    )
}

export default PageTitle;